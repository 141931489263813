@font-face {
    font-family: 'Catchy Mager';
    src: url('./fonts/CatchyMager.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Filena Thin';
    src: url('./fonts/FilenaThin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} 